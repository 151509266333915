import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import { useQuery } from "react-query";
import "react-loading-skeleton/dist/skeleton.css";
import { ModalConfirm } from "tamtam-components";

import {
  getHomeMemberShips,
  getUserMemberShip,
  deleteAssociateMember,
} from "../../api";
import { setIsSuccessPayment, setOrderType } from "../../store";
import _ from "../../i18n";

import PaymentModal from "./PaymentModal";
import UserMemberModal from "./UserMemberModal";

import styles from "./Offers.module.scss";
import { YesLineIcon, YesGreenLineIcon } from "../common/icons/IconYes";
import { NoLineIcon } from "../common/icons/IconNo";
import IconInfo from "../common/icons/IconInfo";
import IconUsers from "../common/icons/IconUsers";
import IconCheckBadge from "../common/icons/IconCheckBadge";
import { IconTrash } from "../common/icons/IconTrash";

const Offers = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { token, clientToken } = auth;
  const isSuccessPayment = useSelector(
    (state) => state.params.isSuccessPayment
  );
  const backUrl = useSelector((state) => state.params.backUrl);
  const orderType = useSelector((state) => state.params.orderType);
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const descriptionAttr = `description${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const sliderLabelAttr = `sliderLabel${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const sliderTextAttr = `sliderText${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const [membersShips, setMembersShips] = useState([]);
  const [selectedMemberShip, setSelectedMemberShip] = useState(null);
  const [myMemberShips, setMyMemberShips] = useState([]);
  const [purchasedMemberShips, setPurchasedMemberShips] = useState([]);
  const [purchasedFormulas, setPurchasedFormulas] = useState([]);
  const [memberShipOrder, setMemberShipOrder] = useState(null);
  const [relatedFormulas, setRelatedFormulas] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showSuccessBar, setShowSuccessBar] = useState(false);
  const [showInfoBar, setShowInfoBar] = useState(false);
  const [showSelectRelatedFormula, setShowSelectRelatedFormula] =
    useState(null);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [currentMemberUser, setCurrentMemberUser] = useState(null);
  const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  let listMemberShips = useQuery(
    ["listMemberShips", token, clientToken],
    async () => {
      if (token || clientToken) {
        try {
          const tok = token || clientToken;
          const response = await getHomeMemberShips(tok);
          if (response?.data?.data) {
            const data = [...response.data.data];
            let tabFormulas = [];
            let tabRelatedFormulas = [];
            let formulaMembershipIds = {};
            data.forEach((i) => {
              i.uaMemberShipFormulas.forEach((f) => {
                formulaMembershipIds[f.id] = i;
              });
            });
            data.forEach((row) => {
              row.sliderValue = row.hasSlider === 1 ? row.sliderMin : 1;
              row.uaMemberShipFormulas.forEach((formula) => {
                tabFormulas.push(formula);
                if (formula?.related?.length > 0) {
                  const tmp = tabFormulas.filter(
                    (i) => i.id === parseInt(formula.related[0])
                  );
                  if (tmp.length > 0) {
                    tabRelatedFormulas.push({
                      membersShip: row,
                      formula,
                      related: {
                        membersShip: formulaMembershipIds[tmp[0].id],
                        formula: tmp[0],
                      },
                    });
                  }
                }
              });
            });
            setRelatedFormulas(tabRelatedFormulas);
            setSelectedMemberShip(data[0]);
            setMembersShips(data);

            if (token) {
              getUserMemberShip(token)
                .then((result) => {
                  const items = result.data.data.data;
                  setMemberShipOrder(result.data.data);
                  const tab = [];
                  const tabPurchasedFormulas = [];
                  let memberShipType = "INDIVIDUAL";
                  items.forEach((item) => {
                    data.forEach((row) => {
                      if (row.id === item.membersShip) {
                        row.sliderValue = item.sliderValue;
                        const formula = row.uaMemberShipFormulas.filter(
                          (i) => i.id === item.formula
                        );
                        tab.push({ membersShip: row, formula: formula[0] });
                        tabPurchasedFormulas.push(item.formula);

                        if (row.type === "FIDUCIARY") {
                          memberShipType = "FIDUCIARY";
                        }
                      }
                    });
                  });
                  dispatch(setOrderType(memberShipType));
                  setMyMemberShips([]);
                  setPurchasedMemberShips(tab);
                  setPurchasedFormulas(tabPurchasedFormulas);
                })
                .catch((e) => {});
            }
          }
          return response.data.data;
        } catch (e) {
          return null;
        }
      }
    }
  );

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "orderId") {
        setShowSuccessBar(true);
        dispatch(setIsSuccessPayment(true));
      }
    }
  }, []);

  useEffect(() => {
    if (selectedMemberShip && relatedFormulas.length > 0) {
      let added = false;
      const tab = relatedFormulas.filter(
        (i) => i.membersShip.id === selectedMemberShip.id
      );
      if (
        tab.length > 0 &&
        !hasMemberShipSelected(
          tab[0].related.membersShip,
          tab[0].related.formula
        )
      ) {
        added = true;
        setShowSelectRelatedFormula(tab[0]);
      }
      if (!added) {
        setShowSelectRelatedFormula(null);
      }
    }
  }, [selectedMemberShip, myMemberShips]);

  const handleSelectMemberShip = (formula, membersShip) => {
    const tabFilter = myMemberShips.filter(
      (i) => i.membersShip.id !== membersShip.id
    );
    const tab = [...tabFilter, { membersShip, formula }];
    setMyMemberShips(tab);
  };

  const handleDeselectMemberShip = (formula) => {
    let tab = myMemberShips.filter((i) => {
      if (i.membersShip.id !== selectedMemberShip.id) {
        return true;
      } else {
        if (i.formula.id === formula.id) {
          return false;
        } else {
          return true;
        }
      }
    });
    if (relatedFormulas.length > 0) {
      const tmp = relatedFormulas.filter(
        (i) =>
          i.related.membersShip.id === selectedMemberShip.id &&
          i.related.formula.id === formula.id
      );
      if (tmp.length > 0) {
        tab = tab.filter((i) => i.membersShip.id !== tmp[0].membersShip.id);
      }
    }
    setMyMemberShips(tab);
  };

  const isDisabledFormulas = (membersShip, formula) => {
    let isDisabled = false;
    if (purchasedMemberShips.length > 0) {
      const tab = purchasedMemberShips.filter(
        (i) =>
          i.membersShip.id === membersShip.id && i.formula.id !== formula.id
      );
      if (tab.length > 0) {
        tab.forEach((i) => {
          if (i.formula.annualPrice >= formula.annualPrice) {
            isDisabled = true;
          }
        });
      }
    }
    if (relatedFormulas.length > 0) {
      const tab = relatedFormulas.filter(
        (i) =>
          i.membersShip.id === membersShip.id && i.formula.id === formula.id
      );
      if (
        tab.length > 0 &&
        !hasMemberShipSelected(
          tab[0].related.membersShip,
          tab[0].related.formula
        )
      ) {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  const hasMemberShipSelected = (membersShip, formula) => {
    let tab = myMemberShips.filter(
      (i) => i.membersShip.id === membersShip.id && i.formula.id === formula.id
    );
    if (tab.length === 0 && purchasedMemberShips.length > 0) {
      tab = purchasedMemberShips.filter(
        (i) =>
          i.membersShip.id === membersShip.id && i.formula.id === formula.id
      );
    }
    return tab.length > 0;
  };

  const handleDeleteMemberShip = (myMemberShip) => {
    let tab = myMemberShips.filter((i) => i !== myMemberShip);

    if (relatedFormulas.length > 0) {
      const tmp = relatedFormulas.filter(
        (i) =>
          i.related.membersShip.id === myMemberShip.membersShip.id &&
          i.related.formula.id === myMemberShip.formula.id
      );
      if (tmp.length > 0) {
        tab = tab.filter((i) => i.membersShip.id !== tmp[0].membersShip.id);
      }
    }
    setMyMemberShips(tab);
  };

  const setSliderValue = (
    membersShip,
    value,
    updateSelectedMemberShip = false
  ) => {
    const data = [...membersShips];
    let updatedMemberShip = null;
    data.forEach((item) => {
      if (item.id === membersShip.id) {
        item.sliderValue = value;
        if (updateSelectedMemberShip) {
          updatedMemberShip = item;
        }
      }
    });
    setMembersShips(data);
    if (updateSelectedMemberShip) {
      setSelectedMemberShip(updatedMemberShip);
    }
  };

  const getYearPrice = (membersShip, formula) => {
    let annualPrice = formula.annualPrice;
    if (membersShip.hasSlider === 1) {
      if (membersShip.sliderValue > membersShip.sliderMin) {
        const step =
          (membersShip.sliderValue - membersShip.sliderMin) /
          membersShip.sliderStep;
        annualPrice += step * formula.annualStepPrice;
      }
    }
    return annualPrice;
  };

  const getBillableYearPrice = (membersShip, formula) => {
    let annualPrice = formula.annualPrice;
    if (membersShip.hasSlider === 1) {
      if (membersShip.sliderValue > membersShip.sliderMin) {
        const step =
          (membersShip.sliderValue - membersShip.sliderMin) /
          membersShip.sliderStep;
        annualPrice += step * formula.annualStepPrice;
      }
    }
    if (purchasedMemberShips.length > 0) {
      const purchased = purchasedMemberShips.filter(
        (p) => p.membersShip.id === membersShip.id
      );
      if (purchased.length === 1) {
        const billedPrice = purchased[0].formula.annualPrice;
        return (
          <div>
            {annualPrice - billedPrice}
            <span>€ /{_("year")}</span>
            <div className={styles.price_barred}>{annualPrice}</div>
          </div>
        );
      }
    }
    return (
      <>
        {annualPrice}
        <span>€ /{_("year")}</span>
      </>
    );
  };

  const getPurchasedTotal = () => {
    let total = 0;
    purchasedMemberShips.forEach((item) => {
      total += item.formula.annualPrice;
    });

    return total;
  };

  const getTotal = () => {
    let total = 0;

    myMemberShips.forEach((myMemberShip) => {
      let annualPrice = myMemberShip.formula.annualPrice;
      if (myMemberShip.membersShip.hasSlider === 1) {
        if (
          myMemberShip.membersShip.sliderValue >
          myMemberShip.membersShip.sliderMin
        ) {
          const step =
            (myMemberShip.membersShip.sliderValue -
              myMemberShip.membersShip.sliderMin) /
            myMemberShip.membersShip.sliderStep;
          annualPrice += step * myMemberShip.formula.annualStepPrice;
        }
      }

      if (purchasedMemberShips.length > 0) {
        const purchased = purchasedMemberShips.filter(
          (p) => p.membersShip.id === myMemberShip.membersShip.id
        );
        if (purchased.length === 1) {
          const billedPrice = purchased[0].formula.annualPrice;
          annualPrice = annualPrice - billedPrice;
        }
      }

      total += annualPrice;
    });
    return total;
  };

  const openPaymentModal = () => {
    setIsOpenModal(true);
  };

  const showRelatedFormulaBox = () => {
    return (
      <div className={styles.pack_related}>
        <span>
          <IconInfo />
        </span>
        <div>
          <p>
            {_("select_related_formula")
              .replace(
                "%formula1%",
                showSelectRelatedFormula.related.formula[nameAttr]
              )
              .replace(
                "%formula2%",
                showSelectRelatedFormula.formula[nameAttr]
              )}
          </p>
          <p
            className={styles.pack_related_btn}
            onClick={() =>
              handleSelectMemberShip(
                showSelectRelatedFormula.related.formula,
                showSelectRelatedFormula.related.membersShip
              )
            }
          >
            {_("add_related_formula").replace(
              "%formula%",
              showSelectRelatedFormula.related.formula[nameAttr]
            )}
          </p>
        </div>
      </div>
    );
  };

  const handleRemoveUser = async () => {
    if (!currentMemberUser) {
      return null;
    }
    setDeleting(true);
    try {
      await deleteAssociateMember(token, currentMemberUser.id);
      setMemberShipOrder({
        ...memberShipOrder,
        members: memberShipOrder.members.filter(
          (i) => i.id !== currentMemberUser.id
        ),
      });
      setCurrentMemberUser(null);
      setDeleting(false);
      setDeleteMemberModalOpen(false);
    } catch (e) {
      setDeleting(false);
    }
  };

  let returnUrl = backUrl || "";
  if (orderType) {
    returnUrl += "?type=" + orderType;
  }

  return (
    <div className={styles.offersPage}>
      {showSuccessBar && (
        <div className={styles.topBar}>
          <IconCheckBadge size="20" /> Vous êtes membre de l'ordre des experts
          comptables{" "}
          {backUrl && (
            <a href={returnUrl} className={styles.backBadge}>
              <i className="icon-ttp-arrow-back"></i> Retour à l'application
            </a>
          )}
        </div>
      )}
      {showInfoBar && (
        <div className={`${styles.topBar} ${styles.topWarning}`}>
          Une fois le paiement confirmé, vous recevrez un e-mail de confirmation
          contenant toutes les informations nécessaires
        </div>
      )}

      {listMemberShips.isFetching ? (
        <div className="lmask"></div>
      ) : (
        selectedMemberShip && (
          <div className={` ${styles.container}`}>
            <div>
              <ul className={styles.tabs}>
                {membersShips.map((membersShip) => (
                  <li
                    onClick={() => setSelectedMemberShip(membersShip)}
                    className={`${styles.tabs_membership} ${
                      selectedMemberShip?.id === membersShip.id
                        ? styles.tabs_membership_active
                        : ""
                    }`}
                  >
                    {membersShip[nameAttr]}
                  </li>
                ))}
              </ul>
              <div
                className={`${styles.leftSide} ${
                  selectedMemberShip?.uaMemberShipFormulas?.length > 3 &&
                  styles.pack_formulas_sm
                }`}
              >
                <div className={styles.pack_detail}>
                  <div className={styles.pack_info}>
                    <div className={styles.pack_info_top}>
                      <h4 className={styles.pack_info_name}>
                        {_("oeccbb_formulas")}
                      </h4>
                    </div>
                    <p>{selectedMemberShip[descriptionAttr]}</p>
                  </div>

                  {selectedMemberShip.hasSlider === 1 && (
                    <div className={styles.pack_formulas_slider}>
                      <div className={styles.pack_formulas_slider_top}>
                        <span>
                          {selectedMemberShip[sliderLabelAttr]}{" "}
                          {selectedMemberShip[sliderTextAttr] && (
                            <Tooltip
                              title={selectedMemberShip[sliderTextAttr]}
                              arrow
                            >
                              <span>
                                <IconInfo size="14" />
                              </span>
                            </Tooltip>
                          )}
                        </span>
                        <span className={styles.pack_formulas_slider_value}>
                          <IconUsers /> {selectedMemberShip.sliderValue}+
                          {selectedMemberShip.sliderValue * 4}
                        </span>
                      </div>
                      <Slider
                        value={selectedMemberShip.sliderValue}
                        step={parseInt(selectedMemberShip.sliderStep)}
                        marks
                        min={parseInt(selectedMemberShip.sliderMin)}
                        max={parseInt(selectedMemberShip.sliderMax)}
                        sx={{
                          color: "primary.light",
                        }}
                        onChange={(e, newValue) =>
                          setSliderValue(selectedMemberShip, newValue, true)
                        }
                        // disabled={purchasedFormulas.includes(formula.id)}
                      />
                    </div>
                  )}

                  <div className={styles.pack_formulas_box}>
                    {selectedMemberShip?.uaMemberShipFormulas?.map(
                      (formula) => (
                        <div
                          className={`${styles.pack_pro} ${
                            purchasedFormulas.includes(formula.id) &&
                            styles.pack_activated
                          } ${
                            hasMemberShipSelected(
                              selectedMemberShip,
                              formula
                            ) && styles.pack_selected
                          }`}
                        >
                          <div>
                            <div className={styles.formula}>
                              <span>{_("formula")}</span>
                              {formula[nameAttr]}
                            </div>
                          </div>

                          <div>
                            <div className={styles.price}>
                              {getYearPrice(selectedMemberShip, formula)}
                              <span>€ /{_("year")}</span>
                            </div>
                            {purchasedFormulas.includes(formula.id) ? (
                              <div className={styles.activated}>
                                <YesLineIcon size="20" /> {_("activated")}
                              </div>
                            ) : isDisabledFormulas(
                                selectedMemberShip,
                                formula
                              ) ? (
                              <div
                                className={`${styles.btn_action} ${styles.btn_disabled}`}
                              >
                                {_("select")}
                              </div>
                            ) : hasMemberShipSelected(
                                selectedMemberShip,
                                formula
                              ) ? (
                              <div
                                className={`${styles.btn_action} ${styles.btn_selected}`}
                                onClick={() =>
                                  handleDeselectMemberShip(formula)
                                }
                              >
                                <YesLineIcon size="20" /> {_("selected")}
                              </div>
                            ) : (
                              <div
                                className={`${styles.btn_action}`}
                                onClick={() =>
                                  handleSelectMemberShip(
                                    formula,
                                    selectedMemberShip
                                  )
                                }
                              >
                                {_("select")}
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className={styles.pack_empty_detail}>
                  <div className={styles.pack_empty_detail_1}>&nbsp;</div>
                  {selectedMemberShip?.uaMemberShipFormulas?.map((formula) => (
                    <div className={styles.pack_empty_detail_2}></div>
                  ))}
                </div>

                <div className={styles.pack_detail_bottom}>
                  {selectedMemberShip.uaMemberShipCategories.map(
                    (category, idx) => (
                      <>
                        <div
                          className={`${styles.pack_app} ${
                            styles.pack_super_cat
                          } ${
                            category.children.length === 0 &&
                            styles.pack_app_hover
                          }`}
                        >
                          <div
                            className={`${styles.pack_app_left} ${styles.pack_app_name}`}
                          >
                            {category[nameAttr]}
                          </div>
                          {selectedMemberShip.uaMemberShipFormulas.map(
                            (formula) =>
                              category.children.length === 0 ? (
                                <div className={styles.pack_app_pro}>
                                  {formula.apps.includes(category.id) ? (
                                    <YesGreenLineIcon />
                                  ) : (
                                    <NoLineIcon />
                                  )}
                                </div>
                              ) : (
                                <div className={styles.pack_app_pro}></div>
                              )
                          )}
                        </div>

                        {category.children.map(
                          (subCategory, subcategoryIdx) => (
                            <>
                              {category.children.length > 0 && (
                                <div
                                  className={`${styles.pack_app} ${
                                    styles.pack_app_cat
                                  } ${
                                    subCategory.children.length === 0 &&
                                    styles.pack_app_hover
                                  }`}
                                >
                                  <div
                                    className={`${styles.pack_app_left} ${styles.pack_app_cat_left} ${styles.pack_app_fonct}`}
                                  >
                                    {subCategory[nameAttr]}
                                    {subCategory[descriptionAttr] && (
                                      <Tooltip
                                        title={subCategory[descriptionAttr]}
                                        arrow
                                      >
                                        <span>
                                          <IconInfo size="14" />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                  {selectedMemberShip.uaMemberShipFormulas.map(
                                    (formula) =>
                                      subCategory.children.length === 0 ? (
                                        <div className={styles.pack_app_pro}>
                                          {formula.apps.includes(
                                            subCategory.id
                                          ) ? (
                                            <YesGreenLineIcon />
                                          ) : (
                                            <NoLineIcon />
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          className={styles.pack_app_pro}
                                        ></div>
                                      )
                                  )}
                                </div>
                              )}

                              {subCategory.children.map((option, optionIdx) => (
                                <div
                                  key={optionIdx}
                                  className={`${styles.pack_app} ${styles.pack_app_hover}`}
                                >
                                  <div
                                    className={`${styles.pack_app_left} ${styles.pack_app_fonct}`}
                                  >
                                    <span>{option[nameAttr]}</span>
                                    {option[descriptionAttr] && (
                                      <Tooltip
                                        title={option[descriptionAttr]}
                                        arrow
                                      >
                                        <span>
                                          <IconInfo size="14" />
                                        </span>
                                      </Tooltip>
                                    )}
                                  </div>
                                  {selectedMemberShip.uaMemberShipFormulas.map(
                                    (formula) => {
                                      if (!formula.apps) {
                                        return null;
                                      }
                                      return (
                                        <div className={styles.pack_app_pro}>
                                          {formula.apps.includes(option.id) ? (
                                            <YesGreenLineIcon />
                                          ) : (
                                            <NoLineIcon />
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ))}
                            </>
                          )
                        )}
                      </>
                    )
                  )}
                </div>

                <div className={`${styles.pack_app} ${styles.pack_app_end}`}>
                  <div className={styles.pack_app_left}></div>
                  {selectedMemberShip?.uaMemberShipFormulas?.map((formula) => (
                    <div className={styles.pack_app_pro}></div>
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.sidebar}>
              <div className={styles.sticky}>
                <div className={styles.pack_sidebar}>
                  <h3 className={styles.pack_sidebar_title}>{_("summary")}</h3>

                  {myMemberShips.length === 0 &&
                  purchasedMemberShips.length === 0 ? (
                    <>
                      <p className={styles.pack_sidebar_noformule}>
                        {_("no_selected_formula")}
                      </p>
                      <p className={styles.pack_sidebar_txt}>
                        {_("select_formula_membership")}
                      </p>
                      {showSelectRelatedFormula && showRelatedFormulaBox()}
                    </>
                  ) : (
                    <>
                      {purchasedMemberShips.map((myMemberShip) => (
                        <div
                          className={`${styles.mypack} ${styles.mypack_green}`}
                        >
                          <div className={styles.mypack_top}>
                            <div className={styles.mypack_name}>
                              {myMemberShip.membersShip[nameAttr]}
                            </div>
                            <span className={styles.mypack_activated}>
                              {_("activated")} <IconCheckBadge size="20" />
                            </span>
                          </div>
                          {myMemberShip.membersShip.hasSlider === 1 && (
                            <div className={styles.pack_formulas_slider}>
                              <div className={styles.pack_formulas_slider_top}>
                                <span>
                                  {myMemberShip.membersShip[sliderLabelAttr]}{" "}
                                  {myMemberShip.membersShip[sliderTextAttr] && (
                                    <Tooltip
                                      title={
                                        myMemberShip.membersShip[sliderTextAttr]
                                      }
                                      arrow
                                    >
                                      <span>
                                        <IconInfo size="14" />
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                                <span
                                  className={styles.pack_formulas_slider_value}
                                >
                                  <IconUsers />{" "}
                                  {myMemberShip.membersShip.sliderValue}+
                                  {myMemberShip.membersShip.sliderValue * 4}
                                </span>
                              </div>
                              <Slider
                                value={myMemberShip.membersShip.sliderValue}
                                step={parseInt(
                                  myMemberShip.membersShip.sliderStep
                                )}
                                marks
                                min={parseInt(
                                  myMemberShip.membersShip.sliderMin
                                )}
                                max={parseInt(
                                  myMemberShip.membersShip.sliderMax
                                )}
                                sx={{
                                  color: "primary.light",
                                }}
                                disabled={true}
                              />
                            </div>
                          )}
                          <div className={styles.mypack_bottom}>
                            <div className={styles.formula}>
                              <span>{_("formula")}</span>
                              {myMemberShip.formula[nameAttr]}
                            </div>
                            <div className={styles.price}>
                              {getYearPrice(
                                myMemberShip.membersShip,
                                myMemberShip.formula
                              )}
                              <span>€ /{_("year")}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                      {myMemberShips.map((myMemberShip) => (
                        <div className={styles.mypack}>
                          <div className={styles.mypack_top}>
                            <div className={styles.mypack_name}>
                              {myMemberShip.membersShip[nameAttr]}
                            </div>
                            <span
                              className={styles.mypack_delete}
                              onClick={() =>
                                handleDeleteMemberShip(myMemberShip)
                              }
                            >
                              <IconTrash size="16" />
                            </span>
                          </div>
                          {myMemberShip.membersShip.hasSlider === 1 && (
                            <div className={styles.pack_formulas_slider}>
                              <div className={styles.pack_formulas_slider_top}>
                                <span>
                                  {myMemberShip.membersShip[sliderLabelAttr]}{" "}
                                  {myMemberShip.membersShip[sliderTextAttr] && (
                                    <Tooltip
                                      title={
                                        myMemberShip.membersShip[sliderTextAttr]
                                      }
                                      arrow
                                    >
                                      <span>
                                        <IconInfo size="14" />
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                                <span
                                  className={styles.pack_formulas_slider_value}
                                >
                                  <IconUsers />{" "}
                                  {myMemberShip.membersShip.sliderValue}+
                                  {myMemberShip.membersShip.sliderValue * 4}
                                </span>
                              </div>
                              <Slider
                                value={myMemberShip.membersShip.sliderValue}
                                step={parseInt(
                                  myMemberShip.membersShip.sliderStep
                                )}
                                marks
                                min={parseInt(
                                  myMemberShip.membersShip.sliderMin
                                )}
                                max={parseInt(
                                  myMemberShip.membersShip.sliderMax
                                )}
                                sx={{
                                  color: "primary.light",
                                }}
                                onChange={(e, newValue) =>
                                  setSliderValue(
                                    myMemberShip.membersShip,
                                    newValue,
                                    true
                                  )
                                }
                              />
                            </div>
                          )}
                          <div className={styles.mypack_bottom}>
                            <div className={styles.formula}>
                              <span>{_("formula")}</span>
                              {myMemberShip.formula[nameAttr]}
                            </div>
                            <div className={styles.price}>
                              {getBillableYearPrice(
                                myMemberShip.membersShip,
                                myMemberShip.formula
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      {showSelectRelatedFormula && showRelatedFormulaBox()}
                      {getTotal() > 0 && (
                        <div className={styles.pack_sidebar_bottom}>
                          {purchasedMemberShips.length > 0 && (
                            <div className={styles.pack_sidebar_secondtotal}>
                              {_("already_paid")}:{" "}
                              <span>{getPurchasedTotal()}</span>{" "}
                              <small>€/{_("htva")}</small>
                            </div>
                          )}
                          <div className={styles.pack_sidebar_total}>
                            {_("total_to_pay")}: <span>{getTotal()}</span>{" "}
                            <small>€/{_("htva")}</small>
                          </div>
                          <div
                            className={`${styles.btn_action}`}
                            onClick={openPaymentModal}
                          >
                            {_("proceed_payment")}
                          </div>
                        </div>
                      )}

                      {memberShipOrder?.hasFiduciary && (
                        <div className={styles.sidebar_users}>
                          <h3 className={styles.title}>Liste des membres</h3>

                          {memberShipOrder?.members.length > 0 && (
                            <>
                              <ul>
                                {memberShipOrder.members.map((member) => (
                                  <li key={member.id}>
                                    <span>
                                      {member.firstName + " " + member.lastName}
                                    </span>
                                    <span
                                      className={styles.delete}
                                      onClick={() => {
                                        setCurrentMemberUser(member);
                                        setDeleteMemberModalOpen(true);
                                      }}
                                    >
                                      <i className="icon-ttp-trash" />
                                    </span>
                                  </li>
                                ))}
                              </ul>
                              <ModalConfirm
                                type="delete"
                                isOpen={deleteMemberModalOpen}
                                inProcess={deleting}
                                onCancel={() => setDeleteMemberModalOpen(false)}
                                onConfirm={handleRemoveUser}
                                title={_("delete_confirm")}
                                text={_("text_delete_member")}
                                labelNo={_("no")}
                                labelYes={_("yes")}
                              />
                            </>
                          )}
                          {memberShipOrder?.members.length < 4 && (
                            <>
                              <p className={styles.sidebar_users_txt}>
                                Il vous reste{" "}
                                {4 - memberShipOrder.members.length} membre(s) à
                                ajouter
                              </p>
                              <div
                                className={`${styles.btn_action}`}
                                onClick={() => setIsOpenUserModal(true)}
                              >
                                Ajouter un membre
                              </div>
                              <UserMemberModal
                                isOpen={isOpenUserModal}
                                closeModal={() => setIsOpenUserModal(false)}
                                afterSave={(user) =>
                                  setMemberShipOrder({
                                    ...memberShipOrder,
                                    members: [
                                      ...memberShipOrder.members,
                                      {
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        id: user.id,
                                      },
                                    ],
                                  })
                                }
                              />
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <PaymentModal
        isOpen={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
        myMemberShips={myMemberShips}
        memberShipOrder={memberShipOrder}
        setShowInfoBar={setShowInfoBar}
      />
    </div>
  );
};

export default Offers;
